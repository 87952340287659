


































































































































































































































































































































































































































































































































  import Vue from 'vue'
  import Component from 'vue-class-component'
  import { vxm } from '@/store'
  import { Watch } from 'vue-property-decorator'
  import { appendSitePrefix } from '@/utils/routeUtils'

  @Component({
    components: {},
  })
  export default class CustomersManagers extends Vue {
    $refs: Vue['$refs'] & {
      addManagerForm: {
        validate: any
      }
    }

    _searchTimerId = null
    hasCustomerGroups = false
    isDeleting = false

    typeCustomer = 'customer'
    typeCustomerGroup = 'customerGroup'

    tabs = null

    /* START
		   Related to view more dialog
		 */
    viewMoreLimit = 3
    viewMoreDialog = false
    viewMoreItems = []
    viewMoreType = null
    /* END
		   Relate to view more dialog
		 */

    /* START
			Related to add new dialog and edit
		 */
    addManagerDialog = false
    addCustomerManagerDialogOpened = false
    addCustomerGroupManagerDialogOpened = false

    editManagerDialogOpened = false
    editManagerDialogWatchers = {
      customerSearch: false,
      managerCustomerSearch: false,
      inputManagerCustomerId: false,
    }

    editManagerId = null

    customers = []
    customerGroups = []
    customerSubGroups = []
    filteredCustomerSubGroups = []
    webshops = []
    managerUserSearchItems = []

    inputCustomerGroupId = null
    inputCustomerSubGroupIds = []
    inputCustomerId = null
    inputManagerCustomerId = null
    inputForcedWebshopId = null
    inputManagerUserIds = []

    customerSearchItems = []
    customerSearchLoading = false
    customerSearch = ''

    managerCustomerSearchItems = []
    managerCustomerSearchLoading = false
    managerCustomerSearch = ''
    /* END
			Related to add new dialog
		 */

    /* START
			Related to tables
		 */
    pagination = {}
    headers = {}

    selectedCustomers = []
    selectedCustomerGroups = []

    customerManagersRows = []
    customerGroupManagersRows = []

    customerManagersLoadingData = true
    customerGroupManagersLoadingData = true

    customerManagersFilters = { customer: [], managerCustomer: [], managerUsers: [] }
    customerManagersActiveFilters = { customer: [], managerCustomer: [], managerUsers: [] }

    customerGroupManagersFilters = { customerGroup: [], customerSubGroups: [], managerCustomer: [], managerUsers: [] }
    customerGroupManagersActiveFilters = {
      customerGroup: [],
      customerSubGroups: [],
      managerCustomer: [],
      managerUsers: [],
    }
    /* END
			Related to tables
		 */

    isSavingInformation = false
    inputInformation = null

    loadingAddManager = false

    // i18n is not initialized here, so add rules at created function
    rules = {}

    appendSitePrefix = appendSitePrefix

    created() {
      this.rules = {
        customer: [(v) => !!v || this.$t('c:validation:This field is required')],
        group: [(v) => !!v || this.$t('c:validation:This field is required')],
        managerCustomer: [(v) => !!v || this.$t('c:validation:This field is required')],
      }

      this.initializeFields()
      this.getInitialData(false)
    }

    initFilters() {
      for (const col in this.customerManagersFilters) {
        const result = []
        for (let i = 0; i < this.customerManagersRows.length; i++) {
          if (col === 'customer') {
            result.push({ id: this.customerManagersRows[i][col].id, value: this.customerManagersRows[i][col].name })
          } else if (col === 'managerCustomer') {
            result.push({ id: this.customerManagersRows[i][col].id, value: this.customerManagersRows[i][col].name })
          } else if (col === 'managerUsers') {
            for (let j = 0; j < this.customerManagersRows[i][col].length; j++) {
              result.push({
                id: this.customerManagersRows[i][col][j].managerUserProfile.userId,
                value: this.getUserProfileFullName(this.customerManagersRows[i][col][j].managerUserProfile),
              })
            }
          }
        }
        const ids = result.map((o) => o.id)
        this.customerManagersFilters[col] = result.filter(({ id }, index) => !ids.includes(id, index + 1))
      }

      for (const col in this.customerGroupManagersFilters) {
        const result = []
        for (let i = 0; i < this.customerGroupManagersRows.length; i++) {
          if (col === 'managerCustomer') {
            result.push({
              id: this.customerGroupManagersRows[i][col].id,
              value: this.customerGroupManagersRows[i][col].name,
            })
          } else if (col === 'customerGroup') {
            result.push({
              id: this.customerGroupManagersRows[i][col].id,
              value: this.customerGroupManagersRows[i][col].name,
            })
          } else if (col === 'customerSubGroups') {
            for (let j = 0; j < this.customerGroupManagersRows[i][col].length; j++) {
              result.push({
                id: this.customerGroupManagersRows[i][col][j].customerSubGroup.id,
                value: this.customerGroupManagersRows[i][col][j].customerSubGroup.name,
              })
            }
          } else if (col === 'managerUsers') {
            for (let j = 0; j < this.customerGroupManagersRows[i][col].length; j++) {
              result.push({
                id: this.customerGroupManagersRows[i][col][j].managerUserProfile.userId,
                value: this.getUserProfileFullName(this.customerGroupManagersRows[i][col][j].managerUserProfile),
              })
            }
          }
        }
        const ids = result.map((o) => o.id)
        this.customerGroupManagersFilters[col] = result.filter(({ id }, index) => !ids.includes(id, index + 1))
      }
    }

    getInitialData(saved) {
      this.$axios
        .get('/v3/customers/managers/get_initial_data')
        .then((response) => {
          const data = response.data.data

          this.customerManagersRows = data.customerManagers
          this.customerGroupManagersRows = data.customerGroupManagers

          this.customerGroups = data.customerGroups
          this.customerSubGroups = data.customerSubGroups
          this.hasCustomerGroups = data.hasCustomerGroups
          this.webshops = data.webshops

          this.inputInformation = data.settings !== null ? data.settings.informationText : null
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          if (saved) {
            this.closeAddManagerDialog()
          }
          this.customerManagersLoadingData = false
          this.customerGroupManagersLoadingData = false
          this.initFilters()
        })
    }

    initializeFields() {
      this.pagination[this.typeCustomer] = {
        descending: true,
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
      }
      this.pagination[this.typeCustomerGroup] = {
        descending: true,
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
      }
      this.pagination[this.typeCustomerGroup] = {
        descending: true,
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
      }

      this.headers[this.typeCustomer] = [
        {
          text: '#',
          value: 'id',
        },
        {
          text: this.$t('c:customers-managers-list-headers:Customer Name'),
          value: 'customer',
          filter: (value) => {
            return this.customerManagersActiveFilters.customer && this.customerManagersActiveFilters.customer.length > 0
              ? this.customerManagersActiveFilters.customer.includes(value.id)
              : true
          },
        },
        {
          text: this.$t('c:customers-managers-list-headers:Customer Manager'),
          value: 'managerCustomer',
          filter: (value) => {
            return this.customerManagersActiveFilters.managerCustomer &&
              this.customerManagersActiveFilters.managerCustomer.length > 0
              ? this.customerManagersActiveFilters.managerCustomer.includes(value.id)
              : true
          },
        },
        {
          text: this.$t('c:customers-managers-list-headers:User Manager'),
          value: 'managerUsers',
          filter: (value) => {
            if (this.customerManagersActiveFilters.managerUsers.length === 0 && value.length === 0) {
              return true
            }
            for (let i = 0; i < value.length; i++) {
              if (
                this.customerManagersActiveFilters.managerUsers &&
                this.customerManagersActiveFilters.managerUsers.length > 0
                  ? this.customerManagersActiveFilters.managerUsers.includes(value[i].managerUserProfile.userId)
                  : true
              ) {
                return true
              }
            }
            return false
          },
        },
        {
          text: this.$t('c:customers-managers-list-headers:Forced Webshop'),
          value: 'webshop.primaryDomain.domain',
        },
        {
          text: this.$t('c:customers-managers-list-headers:Actions'),
          value: 'actions',
        },
      ]
      this.headers[this.typeCustomerGroup] = [
        {
          text: '#',
          value: 'id',
        },
        {
          text: this.$t('c:customers-managers-list-headers:Group Name'),
          value: 'customerGroup',
          filter: (value) => {
            return this.customerGroupManagersActiveFilters.customerGroup &&
              this.customerGroupManagersActiveFilters.customerGroup.length > 0
              ? this.customerGroupManagersActiveFilters.customerGroup.includes(value.id)
              : true
          },
        },
        {
          text: this.$t('c:customers-managers-list-headers:Sub Group Name'),
          value: 'customerSubGroups',
          filter: (value) => {
            if (this.customerGroupManagersActiveFilters.customerSubGroups.length === 0 && value.length === 0) {
              return true
            }
            for (let i = 0; i < value.length; i++) {
              if (
                this.customerGroupManagersActiveFilters.customerSubGroups &&
                this.customerGroupManagersActiveFilters.customerSubGroups.length > 0
                  ? this.customerGroupManagersActiveFilters.customerSubGroups.includes(value[i].customerSubGroup.id)
                  : true
              ) {
                return true
              }
            }
            return false
          },
        },
        {
          text: this.$t('c:customers-managers-list-headers:Customer Manager'),
          value: 'managerCustomer',
          filter: (value) => {
            return this.customerGroupManagersActiveFilters.managerCustomer &&
              this.customerGroupManagersActiveFilters.managerCustomer.length > 0
              ? this.customerGroupManagersActiveFilters.managerCustomer.includes(value.id)
              : true
          },
        },
        {
          text: this.$t('c:customers-managers-list-headers:User Manager'),
          value: 'managerUsers',
          filter: (value) => {
            if (this.customerGroupManagersActiveFilters.managerUsers.length === 0 && value.length === 0) {
              return true
            }
            for (let i = 0; i < value.length; i++) {
              if (
                this.customerGroupManagersActiveFilters.managerUsers &&
                this.customerGroupManagersActiveFilters.managerUsers.length > 0
                  ? this.customerGroupManagersActiveFilters.managerUsers.includes(value[i].managerUserProfile.userId)
                  : true
              ) {
                return true
              }
            }
            return false
          },
        },
        {
          text: this.$t('c:customers-managers-list-headers:Forced Webshop'),
          value: 'webshop.primaryDomain.domain',
        },
        {
          text: this.$t('c:customers-managers-list-headers:Actions'),
          value: 'actions',
        },
      ]
    }

    addManager() {
      if (this.$refs.addManagerForm.validate()) {
        this.loadingAddManager = true

        let data = {}
        if (this.addCustomerManagerDialogOpened) {
          data = {
            id: this.editManagerId,
            type: 'customer',
            customerId: this.inputCustomerId,
            managerCustomerId: this.inputManagerCustomerId,
            managerUserIds: this.inputManagerUserIds,
            forcedWebshopId: this.inputForcedWebshopId,
          }
        } else if (this.addCustomerGroupManagerDialogOpened) {
          data = {
            id: this.editManagerId,
            type: 'customerGroup',
            customerGroupId: this.inputCustomerGroupId,
            customerSubGroupIds: this.inputCustomerSubGroupIds,
            managerCustomerId: this.inputManagerCustomerId,
            managerUserIds: this.inputManagerUserIds,
            forcedWebshopId: this.inputForcedWebshopId,
          }
        }

        this.$axios
          .post('/v3/customers/managers/save', data)
          .then((response) => {
            vxm.alert.success({
              content: this.$t('c:common:Successfully saved') as string,
              title: this.$t('c:common:Success') as string,
            })
            this.getInitialData(true)
          })
          .catch((err) => {
            err.response.data.errors.forEach((v, i) => {
              vxm.alert.error({
                content: v.message as string,
                title: this.$t('c:common:Error') as string,
              })
            })
          })
          .finally(() => {
            this.loadingAddManager = false
          })
      }
    }

    openAddCustomerManagerDialog() {
      this.addManagerDialog = true
      this.addCustomerManagerDialogOpened = true
    }

    openAddGroupManagerDialog() {
      this.addManagerDialog = true
      this.addCustomerGroupManagerDialogOpened = true
    }

    closeAddManagerDialog() {
      this.addManagerDialog = false
      this.addCustomerManagerDialogOpened = false
      this.addCustomerGroupManagerDialogOpened = false
      this.inputCustomerId = null
      this.inputCustomerGroupId = null
      this.inputCustomerSubGroupIds = []
      this.inputManagerCustomerId = null
      this.inputManagerUserIds = []
      this.inputForcedWebshopId = null
      this.filteredCustomerSubGroups = []
      this.editManagerDialogOpened = false
      this.editManagerId = null
    }

    editManager(item, type) {
      if (type === this.typeCustomer || type === this.typeCustomerGroup) {
        this.editManagerDialogWatchers.customerSearch = true
        this.editManagerDialogWatchers.managerCustomerSearch = true
        this.editManagerDialogWatchers.inputManagerCustomerId = true

        this.editManagerDialogOpened = true
        this.editManagerId = item.id
        this.managerCustomerSearchItems = [{ id: item.managerCustomerId, name: item.managerCustomer.name }]
        this.inputManagerCustomerId = item.managerCustomerId
        this.inputManagerUserIds = item.managerUsers.map((item) => item.managerUserId)
        this.inputForcedWebshopId = item.forcedWebshopId
        if (type === this.typeCustomer) {
          this.customerSearchItems = [{ id: item.customerId, name: item.customer.name }]
          this.inputCustomerId = item.customerId
          this.openAddCustomerManagerDialog()
        } else if (type === this.typeCustomerGroup) {
          this.inputCustomerGroupId = item.customerGroupId
          this.inputCustomerSubGroupIds = item.customerSubGroups.map((item) => item.customerSubGroupId)
          this.openAddGroupManagerDialog()
        }
      }
    }

    viewMore(items, type) {
      this.viewMoreItems = items
      this.viewMoreType = type
      this.viewMoreDialog = true
    }

    deleteManager(item, type) {
      this.deleteManagers(item, type)
    }

    getSelected(type) {
      if (type === this.typeCustomer) {
        return this.selectedCustomers
      } else if (type === this.typeCustomerGroup) {
        return this.selectedCustomerGroups
      }
    }

    clearSelected(type) {
      if (type === this.typeCustomer) {
        this.selectedCustomers = []
      } else if (type === this.typeCustomerGroup) {
        this.selectedCustomerGroups = []
      }
    }

    getTableRows(type) {
      if (type === this.typeCustomer) {
        return this.customerManagersRows
      } else if (type === this.typeCustomerGroup) {
        return this.customerGroupManagersRows
      }
    }

    deleteManagers(item, type) {
      if (confirm(this.$t('c:common:Are you sure you want to delete this item(s)?').toString())) {
        this.isDeleting = true

        let selectedRows
        if (item === null) {
          selectedRows = this.getSelected(type)
        } else {
          selectedRows = [item]
        }

        const deleteData = {
          selectedRows: selectedRows,
        }

        this.$axios
          .delete('/v3/customers/managers/delete_managers', { data: deleteData })
          .then((response) => {
            const tableRows = this.getTableRows(type)
            for (let i = 0; i < selectedRows.length; i++) {
              const index = tableRows.indexOf(selectedRows[i])
              tableRows.splice(index, 1)
            }
            this.clearSelected(type)
            vxm.alert.success({
              content: this.$t('c:common:Successfully deleted') as string,
              title: this.$t('c:common:Success') as string,
            })
          })
          .catch((err) => {
            err.response.data.errors.forEach((v, i) => {
              vxm.alert.error({
                content: v.message as string,
                title: this.$t('c:common:Error') as string,
              })
            })
          })
          .finally(() => {
            this.isDeleting = false
          })
      }
    }

    saveInformation() {
      this.isSavingInformation = true

      const saveData = {
        information: this.inputInformation,
      }

      this.$axios
        .post('/v3/customers/managers/save_information', saveData)
        .then((response) => {
          vxm.alert.success({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Success') as string,
          })
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.isSavingInformation = false
        })
    }

    getUserProfileFullName(item) {
      const names = [item.firstname, item.middlename, item.lastname]

      return names.join(' ')
    }

    @Watch('customerSearch')
    onCustomerSearchChanged(val) {
      // On first time edit opening do not make a search since we have the data prefilled
      if (this.editManagerDialogWatchers.customerSearch || val === null || val.length < 3) {
        this.editManagerDialogWatchers.customerSearch = false
        return
      }

      this.customerSearchLoading = true
      this.fetchCustomersDebounced(val, 'customer')
    }

    @Watch('managerCustomerSearch')
    onManagerCustomerSearchChanged(val) {
      // On first time edit opening do not make a search since we have the data prefilled
      if (this.editManagerDialogWatchers.managerCustomerSearch || val === null || val.length < 3) {
        this.editManagerDialogWatchers.managerCustomerSearch = false
        return
      }

      this.managerCustomerSearchLoading = true
      this.fetchCustomersDebounced(val, 'managerCustomer')
    }

    fetchCustomersDebounced(val, type) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.fetchCustomers(val, type)
      }, 500) /* 500ms throttle */
    }

    fetchCustomers(val, type) {
      this.$axios
        .get('/v3/customers/search?query=' + encodeURIComponent(val))
        .then((response) => {
          if (type === 'customer') {
            this.customerSearchItems = response.data.data.customers
            this.customerSearchLoading = false
          } else if (type === 'managerCustomer') {
            this.managerCustomerSearchItems = response.data.data.customers
            this.managerCustomerSearchLoading = false
          }
        })
        .catch((error) => {
          if (type === 'customer') {
            this.customerSearchLoading = false
          } else if (type === 'managerCustomer') {
            this.managerCustomerSearchLoading = false
          }
        })
    }

    @Watch('inputCustomerGroupId')
    populateSubGroups() {
      if (this.inputCustomerGroupId !== null) {
        this.filteredCustomerSubGroups = []
        if (this.customerSubGroups[this.inputCustomerGroupId] !== undefined) {
          this.filteredCustomerSubGroups = this.customerSubGroups[this.inputCustomerGroupId]
        }
      }
    }

    @Watch('inputManagerCustomerId')
    populateManagerUsers() {
      if (this.inputManagerCustomerId !== null) {
        // If this is not an edit dialog first time opening then reset inputted user IDs on change of customer
        if (!this.editManagerDialogWatchers.inputManagerCustomerId) {
          this.inputManagerUserIds = []
          this.editManagerDialogWatchers.inputManagerCustomerId = false
        }
        this.$axios
          .get('/v3/customers/get_users/' + this.inputManagerCustomerId)
          .then((response) => {
            this.managerUserSearchItems = response.data.data.users
          })
          .catch((error) => {
            this.managerUserSearchItems = []
          })
      }
    }
  }
